import React from "react" 
import logo from "../../assets/images/logo-light.acdb767d.svg"
import { Link } from "gatsby"
import arrow from "../../assets/images/arrow.png"


class Menu extends React.Component {

    constructor(){
        super()
        this.state = {
            menu: ""
        }
    }
    close = () => {
        document.querySelector(".greyHalf").classList.remove("animation-open");
        document.querySelector(".menuText").classList.remove("animation-open");
        document.querySelector(".arrow").classList.remove("arrow-visible");

        setTimeout(function(){
            document.getElementById("menu").classList.remove("open-menu");
        }.bind(this), 600)
    }


render() {
    return(
 <div id="menu" className={this.state.menu}>
        <img src={arrow} className="arrow" alt="ICON Worldwide logo" title="ICON Worldwide logo"/> 
        <div className="greyHalf">   
        <div className ="menuContainer" >   
            <Link to="/"><img src={logo} alt="ICON Worldwide logo" title="ICON Worldwide logo"/></Link>
            <div className="menuLinks">
                <div className="link-container"><Link to="/services">Services</Link>        
                    <div className="menuTextInner">
                        <h3>An International Team, Building Global Brands</h3>
                        <p>Our team is comprised of Strategists, Designers, and Developers, committed to delivering smart effective marketing solutions</p>
                    </div>
                </div>
                <div className="link-container"><Link to="/about">About</Link>
                <div className="menuTextInner">
                        <h3>An International Team, Building Global Brands</h3>
                        <p>Our team is comprised of Strategists, Designers, and Developers, committed to delivering smart effective marketing solutions</p>
                    </div>
                </div>
                <div className="link-container"><Link to="/works">Work</Link>
                <div className="menuTextInner">
                        <h3>An International Team, Building Global Brands</h3>
                        <p>Our team is comprised of Strategists, Designers, and Developers, committed to delivering smart effective marketing solutions</p>
                    </div>
                </div>
                <div className="link-container"><Link to="/blog">News</Link>
                <div className="menuTextInner">
                        <h3>An International Team, Building Global Brands</h3>
                        <p>Our team is comprised of Strategists, Designers, and Developers, committed to delivering smart effective marketing solutions</p>
                    </div>
                </div>
                <div className="link-container"><Link to="/contact-us">Contact</Link>
                <div className="menuTextInner">
                        <h3>An International Team, Building Global Brands</h3>
                        <p>Our team is comprised of Strategists, Designers, and Developers, committed to delivering smart effective marketing solutions</p>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div className="menuText">
        <div className="menuContainer" > 
        <div className="menuExit" onClick={this.close}>
            <div></div>
            <div></div>
        </div>
        </div>
        </div>
    </div>
    );
}
}
export default Menu
