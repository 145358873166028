import React from "react"
import background from "../../assets/images/building-lineart-min.png"

export default () => (
  <div id="locations">    
    <img id="img" src={background} alt="ICON Worldwide location" title="ICON Worldwide location"/>
    <div id="locationsInner">
      <div className="title">
        <h2>Locations</h2>
        <div id="partborder"></div>
      </div>
      <div className="enumcities">
        <p>Independent and International</p>
        <ul className="listcities">
          <li>NYC</li>
          <li>Bahrain</li>
          <li>Zurich</li>
          <li>Sofia</li>
          <li>Madrid</li>
        </ul>
      </div>
    </div>
  </div>
)
