import React from "react"
import { Link } from "gatsby"

class ContactUs extends React.Component {

  render() {
    return (
      <div id="contactus">
        <div id="contactusInner">
          <h2>
            Have a <span>project </span>in mind? Want to <span>join </span>our
            <span> team</span>?
          </h2>
          <div className="buttonpart">
            <p>We’d love to hear from you.</p>
            <Link to="/contact-us">
            <button id="button">
              Get your own consultation
            </button>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default ContactUs
