import React from "react"
import  Menu   from "../menu"
import { Link } from "gatsby"

import logo from "../../../assets/images/logo-light.acdb767d.svg"

class servicesMenuHeader extends React.Component {
    constructor(){
        super()
        this.state = {
            menu: ""
        }
    }
    open = () => {
        document.getElementById("menu").classList.add("open-menu");

        setTimeout(function(){
            document.querySelector(".greyHalf").classList.add("animation-open");
            document.querySelector(".menuText").classList.add("animation-open");
        }.bind(this), 600)

        setTimeout(function(){
            document.querySelector(".arrow").classList.add("arrow-visible");
        }.bind(this), 1000)
    }
    render() {
        return(
            <div>
                <Menu/>
                <div style={{ backgroundColor: this.props.bgcolor }} id="servicesMenuNav">
                    <div id="headerNav">
                        <Link to="/"><img src={logo} alt="ICON Worldwide logo" title="ICON Worldwide logo"/></Link>
                        <div className="menuBar" onClick={this.open}>
                            <div className="menuBarLine"></div>
                            <div className="menuBarLine"></div>
                            <div className="menuBarLine"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default servicesMenuHeader