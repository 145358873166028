import React from "react"
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa"
import logo from "../../assets/images/logo-light.acdb767d.svg"
import { Link } from "gatsby"
import ContactUs from "../components/contactus"
import Locations from "../components/locations"

class Footer extends React.Component {


  render() {
    
    return (
      <div>
        <ContactUs/>
        <Locations/>        
        <div id="footer">    
        <div id="footerInner">
          <div className="logo">
            <img src={logo} alt="ICON Worldwide logo" title="ICON Worldwide logo"/>
          </div>
          <div className="firstaddress">
            <p>Beethovenstrasse 9, 8002</p>
            <p>Zurich, Switzerland</p>
            <br />
            <div className="contacts">
              <a href="tel:+41435442764">+41 43 544 27 64</a>
              <a  href="mailto:office@icon-worldwide.com">office@icon-worldwide.com</a>
            </div>
          </div>
          <div className="firstaddress">
            <p>Tintyava 15-17</p>
            <p>1113 Sofia, Bulgaria</p>
            <br />
            <div className="contacts">
              <a href="tel:+359883355072">+359 883 355072</a>
              <a href="tel:+359883304739">+359 883 304739</a>
            </div>
          </div>
          <div className="rightmenu">
            <div className="leftmenu">
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
              <div className="all-rights-reserved">All Rights Reserved</div>
            </div>
            <div className="socialmedia">
              <a  href="https://www.facebook.com/iconww/">
                <FaFacebookF />
              </a>
              <a  href="https://www.instagram.com/iconworldwide/">
                <FaInstagram />
              </a>
              <a  href="https://www.linkedin.com/company/icon-worldwide/">
                <FaLinkedinIn />
              </a>
              <a  href="https://twitter.com/iconworldwide">
                <FaTwitter />
              </a>
            </div>

            <div className="rightestmenu">
              <Link to="/careers">Careers</Link>
              <Link to="/blog">News</Link>
              <Link to="/contact-us">Contact</Link>
            </div>
          </div>
        </div>

       <div id="footerInnerMobile">
        <div className="socialmedia">
              <a  href="https://www.facebook.com/iconww/">
                <FaFacebookF />
              </a>
              <a  href="https://www.instagram.com/iconworldwide/">
                <FaInstagram />
              </a>
              <a  href="https://www.linkedin.com/company/icon-worldwide/">
                <FaLinkedinIn />
              </a>
              <a  href="https://twitter.com/iconworldwide">
                <FaTwitter />
              </a>
        </div>
        <div className="firstaddress">
            <p>Beethovenstrasse 9, 8002</p>
            <p>Zurich, Switzerland</p>
            <div className="contacts">
              <a  href="tel:+41435442764">+41 43 544 27 64</a>
              <a  href="mailto:office@icon-worldwide.com">office@icon-worldwide.com</a>
            </div>
            
            <p>Tintyava 15-17</p>
            <p>1113 Sofia, Bulgaria</p>
            <div className="contacts">
              <a  href="tel:+359883355072">+359 883 355072</a>
              <a  href="tel:+359883304739">+359 883 304739</a>
            </div>
          </div>
        
        <div className="bottom-container">      
            <img src={logo} alt="ICON Worldwide logo" title="ICON Worldwide logo"/>     
          <div className="leftmenu">
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
              <div className="all-rights-reserved">All Rights Reserved</div>
         </div>
         </div> 
       </div>
       </div>
      </div>
    )
}
}

export default Footer
